/* Switch element */
.form-switch {
  @apply relative select-none w-12 my-2;
}

.form-switch label {
  @apply block overflow-hidden cursor-pointer rounded-full h-4;
}

.form-switch label > span:first-child {
  @apply absolute block rounded-full w-6 h-6 right-1/2 -left-1 -top-1 transition-all duration-150 ease-out;
}

.form-switch input[type="checkbox"]:checked + label > span:first-child {
  @apply bg-indigo-500 left-7;
}
