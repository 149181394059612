.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.file-uploader label {
  height: 300px;
  padding: 30px;
  border-color: rgb(75 85 99);
  color: rgb(55 65 81) !important;
}

.file-uploader label span {
  color: rgb(55 65 81) !important;
  text-decoration: none !important;
  display: none;
}
.file-uploader label svg {
  display: none;
}

.file-uploader.inset label {
  border: none;
  position: absolute;
  inset: 0px;
  height: 100%;
  min-width: 100%;
}

.file-uploader label {
  height: 300px;
  padding: 30px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.refined-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.refined-scrollbar::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.refined-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(229 231 235);
}

.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}
.login__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}
.login__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}
.login__google {
  background-color: #4285f4;
}
.login div {
  margin-top: 7px;
}

h1 {
  font-weight: bold;
  font-size: 1.4em;
  margin-bottom: 10px;
}

h2 {
  font-weight: bold;
  font-size: 1.3em;
  margin-bottom: 5px;
  margin-top: 15px;
}

h3 {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 2px;
  margin-top: 10px;
}
h4 {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 2px;
  margin-top: 10px;
}

ul {
  list-style-type: disc;
}

p {
  margin-bottom: 10px;
  margin-top: 10px;
}

.columns {
  float: left;
  width: 33.3%;
  padding: 8px;
}

.price {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.price:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

.price .header {
  background-color: #111;
  color: white;
  font-size: 25px;
}

.price li {
  border-bottom: 1px solid #eee;
  padding: 20px;
  text-align: center;
}

.price .grey {
  background-color: #eee;
  font-size: 20px;
}

.button {
  background-color: #27c17d;
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

@media only screen and (max-width: 600px) {
  .columns {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .col-md-6 {
    float: left;
    width: 50%;
  }
}

.col-md-6 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.screen-border {
  border: 5px solid #bce9d4;
  border-radius: 15px;
  height: 350px;
}
